import type { Resolvers } from './generated/resolvers'
import { ContactInputSchema } from './generated/validators'
import type { FailureResult } from './generated'

export const resolvers: Resolvers = {
  ContactResult: {
    __resolveType(obj) {
      return obj.success ? 'SuccessResult' : 'FailureResult'
    },
  },
  Query: {},
  Mutation: {
    contact: async (_parent, { input }, { logger, prisma }) => {
      const result = await ContactInputSchema().safeParseAsync(input)

      if (!result.success) {
        logger.error('received invalid contact input', { input, result })

        return {
          success: false,
          issues: result.error.issues,
        } as FailureResult
      }

      logger.info('received valid input.', { data: result.data })

      const { id } = await prisma.contactMessage.create({ data: result.data })

      return { success: true, id }
    },
  },
}
