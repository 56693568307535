import { Icon } from '@components/Icon'
import { PopoverTrigger, usePopover } from '@components/Popover'
import { css } from '@emotion/react'
import { FC, Fragment, ReactNode, useState } from 'react'

const Settings: FC<{ children: ReactNode[] }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { refs, getReferenceProps, getFloatingProps } = usePopover({
    isOpen,
    onOpenChange: setIsOpen,
  })

  const triggerStyles = css`
    height: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 0.25rem;

    @media (min-width: 700px) {
      display: none;
    }

    .indicator {
      display: none;
    }

    svg {
      transition: transform 180ms ease-in-out;
      transform: rotate(0deg);
    }

    &.open svg {
      transform: rotate(270deg);
    }
  `

  const contentStyles = css`
    position: absolute;
    top: 1rem;
    left: 0;
    right: 0;
    block-size: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: rgb(var(--color-secondary-200) / 70%);
    padding-inline: 1.5rem;
    gap: 1ch;
    z-index: -1;
    transform: scaleY(0);
    transform-origin: top;
    transition-property: opacity;
    transition-duration: 180ms;
    transition-timing-function: ease-out;
    transition-delay: 100ms;
    backdrop-filter: blur(2px);
    opacity: 0;

    @media (prefers-reduced-motion: no-preference) {
      transition-property: opacity, transform;
    }

    > button {
      opacity: 0;
      transition-property: opacity;
      transition-duration: 100ms;
      transition-timing-function: ease-out;
      transition-delay: 0ms;

      @media (prefers-reduced-motion: no-preference) {
        transition-property: opacity, transform;
      }
    }

    &:where(.open) {
      opacity: 1;
      transform: scaleY(1) translateY(2rem);
      transition-delay: 0ms;

      > button {
        opacity: 1;
        transform: scale(1, 1);
        transition-timing-function: ease-in;
        transition-delay: 180ms;
      }
    }

    @media (min-width: 700px) {
      position: static;
      display: contents;
      height: revert;
      padding: 0;
      opacity: 1;

      > button {
        opacity: 1;
        transform: scale(1, 1);
      }
    }
  `

  return (
    <Fragment>
      <PopoverTrigger
        css={triggerStyles}
        className={isOpen ? 'open' : ''}
        aria-label="preferences"
        ref={refs.setReference}
        isOpen={isOpen}
        {...getReferenceProps()}
      >
        <Icon icon="gear" />
      </PopoverTrigger>
      <div
        css={contentStyles}
        className={isOpen ? 'open' : ''}
        aria-label="Preferences"
        ref={refs.setFloating}
        {...getFloatingProps()}
      >
        {children}
      </div>
    </Fragment>
  )
}

export default Settings
