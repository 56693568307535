import type { Theme } from '@emotion/react'
import '@fontsource/ubuntu/latin.css'
import '@fontsource-variable/kreon'

const theme: Theme = {
  name: 'karmic-koala',
  mode: 'light' as const,

  codeHighlightTheme: 'base16/solarized-light',

  colors: {
    background: '#E0F7FA',
    // accent: '#F44336',
    accent: '#FDDDD8',
    text: '#223',
    primary: '#00838F',
    // secondary: '#00BCD4',
    secondary: '#00ABC3',
    shadow: '#223',
    onPrimary: '#ffffff',
    // onSecondary: '#E0F7FA',
    onSecondary: '#ffffff',
    error: '#F44336',
    // error: '#D50000',
    onError: '#ffffff',
    codeHighlightBackground: '#fff',
  },

  fonts: {
    heading: {
      family: 'KreonVariable, serif',
    },

    text: {
      family: 'Ubuntu, sans-serif',
    },
  },
}

export default theme
