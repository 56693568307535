import type { Theme } from '@emotion/react'

import '@fontsource-variable/signika-negative'
import '@fontsource/dosis/latin.css'

const theme: Theme = {
  name: 'daring-darling',
  mode: 'dark' as const,

  codeHighlightTheme: 'base16/dracula',

  colors: {
    background: '#1F1D36',
    accent: '#e9a6a6',
    // muted: '#845ec2',
    text: '#bcbcbc',
    primary: '#e9a6a6',
    secondary: '#3f3351',
    shadow: 'black',

    onPrimary: '#ffffff',
    onSecondary: '#ffffff',

    // error: '#af0606',
    error: '#f06c97',
    onError: '#ffffff',

    codeHighlightBackground: '#3f3351',
  },

  fonts: {
    heading: {
      family: 'Signika NegativeVariable, sans-serif',
    },

    text: {
      family: 'Dosis, sans-serif',
      base: '1.1rem',
    },
  },
}

export default theme
