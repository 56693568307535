import type { ImageLoader } from 'next/image'

// import { log } from './logger'

// TODO convert to function so to lazy load the flags
import EnFlag from '../assets/flags/united-kingdom-flag.svg'
import FrFlag from '../assets/flags/france-flag.svg'
import EsFlag from '../assets/flags/spain-flag.svg'
import ItFlag from '../assets/flags/italy-flag.svg'

export const flags: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
  en: EnFlag,
  fr: FrFlag,
  es: EsFlag,
  it: ItFlag,
}

export const graphcmsImageLoader: ImageLoader = ({ src, width }) => {
  let url: URL | undefined
  try {
    url = new URL(src)
  } catch (e) {
    // Errors handled below
  }

  if (url?.host !== 'media.graphassets.com') {
    throw new Error('Not a valid graphcms image url')
  }

  if (url.protocol !== 'https:') {
    throw new Error(`https image url is required. received ${url.protocol}`)
  }

  if (url.pathname?.includes('resize')) {
    return src.replace(/width:[0-9]+/, `width:${width}`)
  }

  return `${url.protocol}//${url.host}/resize=fit:clip,width:${width}${url.pathname}`
}

export const base64DataUrlFromImageUrl = async (url: string) => {
  const response = await fetch(url)

  if (!response.ok) {
    throw new Error(`unexpected response ${response.statusText}`)
  }

  const contentType = response.headers.get('Content-Type')
  const data = Buffer.from(await response.arrayBuffer()).toString('base64')

  return `data:${contentType};base64,${data}`
}
