import {
  type FC,
  createRef,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import styled from '@emotion/styled'
import { throttle } from 'lodash'

import Config from '../../config'
import useMenu from '@hooks/useMenu'
import Container from './Menu'
import Hamburger from './Hamburger'
import NavLink from './NavLink'
import MenuList from './List'
import Settings from './Settings'
import { BrandIcon } from '../Icon'
import { Logo } from './Logo'
import { LangSwitcherPopover } from '../LangSwitcher'
import { ThemeSwitcherPopover } from '../ThemeSwitcher'

const ButtonGroup = styled.div`
  gap: 0.5cqi;

  *:where(button, a) {
    font-size: var(--font-text-xs);
    padding-inline: 0.2em;
  }
`

const Menu: FC = () => {
  const { t } = useTranslation('common')
  const { menuItems, isOpen, setIsOpen } = useMenu()
  const prevScrollPos = useRef(0)
  const [visible, setVisible] = useState(true)
  const menuRef = createRef<HTMLUListElement>()

  const handleScroll = useCallback(
    throttle(() => {
      const currentScrollPos = window.pageYOffset
      const minScrollOffset = 600
      const sensitivity = 40

      const isPastScrollOffest = currentScrollPos < minScrollOffset
      const isScrollingUp =
        prevScrollPos.current > currentScrollPos + sensitivity
      const change = prevScrollPos.current - currentScrollPos

      if (sensitivity > Math.abs(prevScrollPos.current - currentScrollPos)) {
        return
      }

      setVisible(isScrollingUp || isPastScrollOffest)

      prevScrollPos.current = currentScrollPos
    }, 500),
    []
  )

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Container aria-label="primary menu" className={visible ? '' : 'hidden'}>
      <Link href="/">
        <Logo />
      </Link>
      <MenuList isOpen={isOpen} data-testid="menu" id="menu" ref={menuRef}>
        {menuItems.map(({ name, path, active }) => (
          <NavLink href={path} key={path} active={active} tabIndex={0}>
            {name}
          </NavLink>
        ))}
      </MenuList>
      <ButtonGroup className="flex items-center justify-end">
        <Settings>
          <ThemeSwitcherPopover />
          <LangSwitcherPopover />
        </Settings>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={Config.siteRepo.url}
          title={t('view_site_repo', { platform: Config.siteRepo.platform })}
        >
          <BrandIcon icon="github" fixedWidth />
          <span className="sr-only">See The Code</span>
        </a>
        <Hamburger menuRef={menuRef} isOpen={isOpen} setIsOpen={setIsOpen} />
      </ButtonGroup>
    </Container>
  )
}

export default Menu
