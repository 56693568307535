import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import useTranslation from 'next-translate/useTranslation'
import Config from '../config'

type Socials = {
  name: string
  url: string
  title: string
  icon: IconProp
}

const useSocialLinks = (): Socials[] => {
  const { gitlab, github, goodreads, linkedin, mastodon } = Config.socials
  const { t } = useTranslation('common')

  // TOOD translate link titles
  const links: Socials[] = [
    {
      name: 'LinkedIn',
      url: `https://www.linkedin.com/in/${linkedin}/`,
      title: t('more_about_me_on', { name: 'linkedin' }),
      icon: ['fab', 'linkedin'],
    },
    // {
    //   name: 'Twitter',
    //   url: `https://twitter.com/@${twitter}`,
    //   title: 'Follow me on Twitter',
    //   icon: 'twitter',
    // },
    {
      name: 'Mastodon',
      url: `https://fosstodon.org/@${mastodon}`,
      title: t('follow_me_on', { name: 'mastodon' }),
      icon: ['fab', 'mastodon'],
    },
    {
      name: 'GitLab',
      url: `https://gitlab.com/${gitlab}`,
      title: t('see_my_projects_on', { name: 'gitlab' }),
      icon: ['fab', 'gitlab'],
    },
    // {
    //   name: 'GitHub',
    //   url: `https://github.com/${github}`,
    //   title: 'see_my_projects_on',
    //   icon: ['fab', 'github'],
    // },
    {
      name: 'GoodReads',
      url: `https://goodreads.com/${goodreads}`,
      title: t('see_what_i_read', { name: 'goodreeds' }),
      icon: ['fab', 'goodreads'],
    },
    {
      name: t('rss_feed'),
      url: `/feed`,
      title: t('follow_me_on', { name: t('rss_feed') }),
      icon: 'rss-square',
    },
  ]

  return links
}

export default useSocialLinks
