import { Fragment, useState, type FC } from 'react'
import { useRouter } from 'next/router'
import { css } from '@emotion/react'
import useTranslation from 'next-translate/useTranslation'
import styled from '@emotion/styled'

import { List } from '@components/Footer/Nav'
import { PopoverContent, PopoverTrigger, usePopover } from '@components/Popover'
import { flags } from '@utils/image'
// import TranslateIcon from '../../assets/translate.svg'

interface Props {
  onSwitch?(): unknown
}

const LangSwitcher: FC<Props> = ({ onSwitch }) => {
  const router = useRouter()

  const { pathname, asPath, query, locales, locale: currentLocale } = router

  return (
    <Fragment>
      <List>
        {locales?.map((locale) => {
          const Flag = flags[locale]

          // Retrieves language name in target language
          // TODO consider degradation for unsupported browsers or polyfill
          const displayName = new Intl.DisplayNames(locale, {
            type: 'language',
          }).of(locale)

          return (
            <li key={locale}>
              <button
                css={css`
                  display: flex;
                  flex-flow: row nowrap;
                  gap: 4px;

                  &.active {
                    font-weight: bold;
                  }

                  svg {
                    filter: drop-shadow(
                      1px 1px 1px rgb(var(--color-shadow) / 50%)
                    );
                  }
                `}
                onClick={() => {
                  document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000; path=/`

                  router.push({ pathname, query }, asPath, {
                    locale,
                    scroll: false,
                  })

                  if (onSwitch) onSwitch()
                }}
                className={locale === currentLocale ? 'active' : ''}
                aria-current={locale === currentLocale}
              >
                <Flag />

                <span lang={locale}>{displayName}</span>
              </button>
            </li>
          )
        })}
      </List>
    </Fragment>
  )
}

const LangSwitcherPopover: FC = () => {
  const { t } = useTranslation('common')
  const { locale: currentLocale } = useRouter()
  const [isOpen, setIsOpen] = useState(false)

  const Flag = flags[String(currentLocale)]

  const {
    isMounted,
    transitionStyles,
    x,
    y,
    strategy,
    refs,
    getReferenceProps,
    getFloatingProps,
  } = usePopover({ isOpen, onOpenChange: setIsOpen })

  const posStyles = css({
    position: strategy,
    top: y ?? 0,
    left: x ?? 0,
    ...transitionStyles,
  })

  // TODO use tailwind breakpoints for this?
  const Label = styled.div`
    display: contents;

    // svg {
    //   display: none;
    // }

    // span {
    //   display: none;
    // }

    // @media (min-width: 600px) {
    //   span {
    //     display: block;
    //   }
    //   svg {
    //     display: none;
    //   }
    // }

    // @media (min-width: 700px) {
    //   span {
    //     display: revert;
    //   }
    //   svg {
    //     display: revert;
    //   }
    // }
  `

  return (
    <Fragment>
      <PopoverTrigger
        ref={refs.setReference}
        isOpen={isOpen}
        {...getReferenceProps()}
      >
        {/* <TranslateIcon css={{ fill: 'currentColor' }} /> */}
        <Label>
          <Flag />
          <span>{t(currentLocale as string)}</span>
        </Label>
      </PopoverTrigger>
      <PopoverContent
        ref={refs.setFloating}
        css={posStyles}
        isMounted={isMounted}
        {...getFloatingProps()}
      >
        <LangSwitcher onSwitch={() => setIsOpen(false)} />
      </PopoverContent>
    </Fragment>
  )
}

export { LangSwitcher, LangSwitcherPopover }
