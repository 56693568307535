import type { Theme } from '@emotion/react'
import '@fontsource-variable/oswald'
import '@fontsource/merriweather/latin.css'
// import 'highlight.js/styles/base16/tomorrow.css'

const theme: Theme = {
  name: 'warm-antique',
  mode: 'light' as const,

  codeHighlightTheme: 'base16/solarized-light',

  colors: {
    background: '#fffbf5',
    accent: '#ffefd6',
    // muted: '#f7dfd4',
    text: '#873e4c',
    primary: '#873e4c',
    secondary: '#e2b091',
    // shadow: 'darkslategray',
    shadow: '#4c3d33',

    onPrimary: '#4e2025',
    onSecondary: '#4e2025',

    // error: '#af0606',
    error: 'crimson',
    onError: '#ffffff',

    codeHighlightBackground: 'papayawhip',
  },

  fonts: {
    heading: {
      family: 'Futura, "Arial Narrow", sans-serif',
      base: '1.1rem',
    },

    text: {
      family: 'Merriweather, Georgia, Times, "Times New Roman", serif',
      base: '0.95rem',
    },
  },
}

export default theme
