import { Fragment, type FC, useCallback, useContext, useState } from 'react'
import { css, type Theme } from '@emotion/react'

import { themes } from '../../theme'
import { ThemeContext } from '../../theme/Context'
import { PopoverTrigger, PopoverContent, usePopover } from '@components/Popover'
import { List } from '@components/Footer/Nav'
import { Icon, StackIcons } from '@components/Icon'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  onSwitch?(): unknown
}

const ThemeSwitcher: FC<Props> = ({ onSwitch }) => {
  const { theme: currentTheme, setTheme } = useContext(ThemeContext)

  const switchThemes = useCallback(
    (newTheme: Theme) => {
      localStorage.setItem('theme', newTheme.name)
      if (typeof setTheme !== 'undefined') {
        setTheme(newTheme.name)
      }

      document.documentElement.classList.remove(currentTheme)
      document.documentElement.classList.add(newTheme.name)
      if (onSwitch) onSwitch()
    },
    [setTheme, currentTheme, onSwitch]
  )

  return (
    <Fragment>
      <List>
        {Object.values(themes).map((theme) => (
          <li
            key={theme.name}
            className={theme.name === currentTheme ? 'font-bold' : ''}
            aria-current={theme.name === currentTheme}
          >
            <button
              onClick={() => {
                switchThemes(theme)
              }}
            >
              <StackIcons>
                <Icon
                  icon="square"
                  css={css`
                    color: ${theme.colors.secondary} !important;
                    filter: drop-shadow(0 0 2px ${theme.colors.accent});
                  `}
                  fixedWidth
                />
                <Icon
                  icon={theme.icon}
                  title={theme.mode}
                  transform="shrink-5"
                  color={theme.colors.onSecondary}
                  fixedWidth
                />
              </StackIcons>
              {theme.displayName}
            </button>
          </li>
        ))}
      </List>
    </Fragment>
  )
}

const ThemeSwitcherPopover: FC = () => {
  const { t } = useTranslation('common')
  const { theme: themeName } = useContext(ThemeContext)

  const theme = themes[themeName]

  const [isOpen, setIsOpen] = useState(false)
  const {
    isMounted,
    transitionStyles,
    x,
    y,
    strategy,
    refs,
    getReferenceProps,
    getFloatingProps,
  } = usePopover({ isOpen, onOpenChange: setIsOpen })

  const posStyles = css({
    position: strategy,
    top: y ?? 0,
    left: x ?? 0,
    ...transitionStyles,
  })

  return (
    <Fragment>
      <PopoverTrigger
        ref={refs.setReference}
        isOpen={isOpen}
        {...getReferenceProps()}
      >
        <StackIcons>
          <Icon icon="square" fixedWidth />
          <Icon
            icon={theme.icon}
            style={{ color: theme.colors.secondary }}
            transform="shrink-6"
            fixedWidth
          />
        </StackIcons>
        {t('theme')}
      </PopoverTrigger>
      <PopoverContent
        aria-label="Theme Switcher"
        ref={refs.setFloating}
        css={posStyles}
        isMounted={isMounted}
        {...getFloatingProps()}
      >
        <ThemeSwitcher onSwitch={() => setIsOpen(false)} />
      </PopoverContent>
    </Fragment>
  )
}

export { ThemeSwitcher, ThemeSwitcherPopover }
