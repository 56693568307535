import type { FC } from 'react'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import useSocialLinks from '../../hooks/useSocialLinks'
import useHeaderMenuItems from '../../hooks/useHeaderMenuItems'
import OutboundLink from '../OutboundLink'
import Copyright from './Copyright'
import Wrapper, { Grid } from './Wrapper'
import Nav, { Heading, List } from './Nav'
import { Icon, BrandIcon } from '../Icon'
import { ThemeSwitcher } from '../ThemeSwitcher'
import { LangSwitcher } from '../LangSwitcher'

const Footer: FC = () => {
  const socialLinks = useSocialLinks()
  const menuItems = useHeaderMenuItems()
  const { t } = useTranslation('common')

  return (
    <Wrapper>
      <Grid>
        <Nav aria-label={t('main_nav')}>
          <Heading>{t('main_nav')}</Heading>
          <List>
            {menuItems.map(({ name, path, icon }) => (
              <li key={name}>
                <Link href={path} title={name}>
                  {icon && <Icon icon={icon} listItem />}
                  {name}
                </Link>
              </li>
            ))}
          </List>
        </Nav>
        <Nav aria-label={t('stay_in_touch')}>
          <Heading>{t('stay_in_touch')}</Heading>
          <List>
            {socialLinks.map(({ name, url, title, icon }) => (
              <li key={name}>
                <OutboundLink href={url} title={title}>
                  <Icon icon={icon} listItem />
                  {name}
                </OutboundLink>
              </li>
            ))}
          </List>
        </Nav>
        <Nav aria-label={t('theme_switcher')}>
          <Heading>{t('theme_switcher')}</Heading>
          <ThemeSwitcher />
        </Nav>
        <Nav aria-label={t('lang_switcher')}>
          <Heading>{t('lang_switcher')}</Heading>
          <LangSwitcher />
        </Nav>
        <Copyright />
      </Grid>
    </Wrapper>
  )
}

export { Footer as default, Copyright, Nav }
