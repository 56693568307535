import { css } from '@emotion/react'
import '@fontsource/kaushan-script'

export const Logo = () => (
  <div
    css={css`
      --shadow-depth: 0.07em;

      flex-shrink: 0;
      font-family: var(--font-heading);
      font-family: 'Kaushan Script', cursive;
      font-size: clamp(1rem, 1cqi + 0.6rem, 1.4rem);
      font-weight: 400;
      filter: drop-shadow(
        var(--shadow-depth) var(--shadow-depth) calc(var(--shadow-depth) / 2)
        rgb(var(--color-shadow) / 70%)
      );

      div {
        @supports (background-clip: text) or (-webkit-background-clip: text) {
          &:hover {
            background-image: linear-gradient(
              -9deg,
              rgb(var(--color-accent-200)),
              rgb(var(--color-accent-800)),
              rgb(var(--color-accent-100))
            );
            background-clip: text;
            color: transparent;
          }
        }
      }
    `}
  >
    {/* <Icon icon="code" /> */}
    <div id="logo">Ozzy Ndiaye</div>
  </div>
)
