import { createRef, useCallback, useEffect } from 'react'
import type { FC, SetStateAction, Dispatch, RefObject } from 'react'
import { useRouter } from 'next/router'

import { Icon } from '../Icon'

interface Props {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  menuRef: RefObject<HTMLElement | undefined>
}

const Hamburger: FC<Props> = ({ isOpen, setIsOpen, menuRef }) => {
  const router = useRouter()
  const ref = createRef<HTMLButtonElement>()

  const closeMenu = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  // TODO combine effects if needed
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event?.key && event.key === 'Escape') {
        closeMenu()
      }
    }

    document.addEventListener('keydown', listener)
    router.events.on('routeChangeStart', closeMenu)

    return () => {
      document.removeEventListener('keydown', listener)
      router.events.off('routeChangeStart', closeMenu)
    }
  }, [setIsOpen, closeMenu, router.events])

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      // TODO understand type
      if (
        !menuRef.current ||
        !ref.current ||
        menuRef.current.contains(event.target as Node) ||
        ref.current.contains(event.target as Node)
      ) {
        return
      }

      closeMenu()
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, menuRef, closeMenu])

  const iconName = isOpen ? 'close' : 'bars'

  return (
    <button
      ref={ref}
      id="hamburger"
      data-test="open-menu"
      data-testid="open-menu"
      onClick={() => setIsOpen(!isOpen)}
      tabIndex={-1}
      aria-haspopup="true"
      aria-expanded={isOpen}
      aria-hidden
    >
      <Icon icon={iconName} />
    </button>
  )
}

export default Hamburger
