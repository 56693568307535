import styled from '@emotion/styled'

import { desktopStyles } from './List'

export default styled.nav`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  right: 0;
  left: 0;
  block-size: var(--navbar-height);
  box-sizing: content-box;
  padding-inline: max(min(2rem, 4vw));
  margin: 0 auto;

  // padding-inline: clamp(0.3em, 1vmin + 0.5em, 1.5em);

  a,
  button {
    color: rgb(var(--color-on-secondary));

    svg {
      max-block-size: 1.2rem;
      inline-size: 1.2rem;
    }

    &:is(:hover, :focus-visible) {
      outline: none;
      color: rgb(var(--color-accent-500));
    }
  }

  @media screen and (min-width: 550px) {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 0.5rem;

    > ul {
      ${desktopStyles}
    }

    button#hamburger {
      display: none;
    }
  }

  &.hidden:not(:has(:focus)) {
    transform: translateY(calc(-1 * var(--navbar-height)));
  }

  transition: transform 120ms ease-out;
  box-shadow: 0 4px 2px rgb(var(--color-shadow) / 20%);
  background-color: rgb(var(--color-secondary-50));
`
