import styled from '@emotion/styled'

const Wrapper = styled.footer`
  --page-padding: min(4vw, 2rem);

  grid-area: footer;
  position: relative;
  isolation: isolate;
  display: flex;
  justify-content: center;
  inline-size: 100%;
  color: rgb(var(--color-on-secondary));
  background-color: rgb(var(--color-secondary-50));
  padding: 3vh var(--page-padding);

  // &::before {
  //   position: absolute;
  //   display: block;
  //   z-index: -1;
  //   content: '';
  //   transform: skewY(-3deg);
  //   top: -3vw;
  //   height: 6vw;
  //   right: 0;
  //   left: 0;
  //   background-color: rgb(var(--color-secondary-200));
  // }

  a,
  button {
    color: rgb(var(--color-on-secondary));
  }

  button:focus-visible {
    outline: none;
  }
`

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-inline-size: var(--page-max-width);
  inline-size: 100%;
  gap: 1rem min(2rem, 1vmax);

  @media (min-width: 320px) {
    display: flex;
    flex-flow: row wrap;
    justify-items: center;
    column-gap: min(5vw, 1.8rem);
  }
`

export { Wrapper as default, Grid }
