import type { FC } from 'react'
import styled from '@emotion/styled'
import {
  FontAwesomeIcon,
  type FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import type { IconName } from '@fortawesome/fontawesome-svg-core'

export const Icon: FC<FontAwesomeIconProps> = ({
  className,
  ...otherProps
}) => <FontAwesomeIcon {...otherProps} className={className} />

interface BrandIconProps extends FontAwesomeIconProps {
  icon: IconName
}

export const BrandIcon: FC<BrandIconProps> = ({ icon }) => (
  <Icon icon={['fab', icon]} />
)

export const StackIcons = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 1.1rem;
  block-size: 1.1rem;
  color: currentcolor;

  svg {
    position: absolute;
    block-size: 100%;
    inline-size: 100%;
  }

  svg:nth-of-type(1) {
    color: currentcolor;
  }

  svg:nth-of-type(2) {
    inline-size: revert;
  }
`
