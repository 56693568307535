import type { FC, ReactNode, Dispatch, SetStateAction } from 'react'
import { createContext, useState, useEffect } from 'react'
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { themes, defaultThemes } from './'

interface ContextProps {
  theme: string
  setTheme?: Dispatch<SetStateAction<string>>
}

const ThemeContext = createContext<ContextProps>({
  theme: defaultThemes.light,
})

interface Props {
  children: ReactNode
}

const ThemeProvider: FC<Props> = ({ children }) => {
  const [theme, setTheme] = useState(defaultThemes.light)
  useEffect(() => {
    const userTheme = localStorage.getItem('theme')
    if (userTheme) {
      setTheme(userTheme)
    }
  }, [])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <EmotionThemeProvider theme={themes[theme]}>
        {children}
      </EmotionThemeProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeProvider }
