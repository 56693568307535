import { stitchSchemas } from '@graphql-tools/stitch'
import { makeExecutableSchema } from '@graphql-tools/schema'
import { resolvers } from './resolvers'
import { createGraphcmsSubSchema } from './graphcms-executor'
import * as typeDefs from './schema.graphql'
import { base64DataUrlFromImageUrl } from '@utils/image'
import readingTime from 'reading-time'
import Config from '../config'
import type { Asset, Page, Post } from './generated'
import { log } from '@utils/logger'

const createExecutableSchema = async () => {
  // The local api executable schema
  const nextSubSchema = makeExecutableSchema({ typeDefs, resolvers })

  // The remote graphcms schema
  const graphcmsSubSchema = await createGraphcmsSubSchema()

  // The combined result with no merge overrides
  const executableSchema = stitchSchemas({
    subschemas: [nextSubSchema, graphcmsSubSchema],
    typeDefs: `
    type ReadingTime {
      minutes: Float!
      time: Int!
      words: Int!
      text: String!
    }

    type TocEntry {
      id: String
      value: String!
      depth: Int!
      children: [TocEntry]
    }

    extend type Post {
      excerpt(maxLength: Int): String!
      content: String!
      toc: [TocEntry]
      readingTime: ReadingTime!
    }

    extend type Page {
      content: String!
    }

    extend type Asset {
      dataURL: String!
    }
    `,
    resolvers: {
      Page: {
        content: async (parent: Page) => {
          const { processMarkdown } = await import('@utils/content')

          return processMarkdown(parent.md)
        },
      },
      Post: {
        content: async (parent: Post) => {
          const { processMarkdown } = await import('@utils/content')

          return processMarkdown(parent.md)
        },

        toc: async (parent: Post) => {
          const { extractMarkdownToc } = await import('@utils/content')
          return extractMarkdownToc(parent.md)
        },

        excerpt: async (parent: Post, args: { maxLength: number }) => {
          const { extractMarkdownExcerpt } = await import('@utils/content')

          return extractMarkdownExcerpt(parent.md, args.maxLength)
        },

        readingTime: (parent: Post) => {
          return readingTime(parent.md)
        },
      },
      Asset: {
        dataURL: async (parent: Asset) => {
          if (!String(parent.url).startsWith(Config.graphcmsCdnUrl)) {
            throw new Error(
              'Can only use dataURL with graphcms image urls' + parent.url
            )
          }

          const url = parent.url.replace(/width:[0-9]+/, 'width:15')
          return await base64DataUrlFromImageUrl(url)
        },
      },
    },
  })

  if (process.env.ENABLE_MOCKS === 'true') {
    const { mockSchema } = await import('./mock')

    return mockSchema(executableSchema)
  }

  return executableSchema
}

export { createExecutableSchema }
