import type { Theme } from '@emotion/react'
import '@fontsource/libre-baskerville/latin.css'
import '@fontsource/libre-franklin/latin.css'

/**
 * TODO Set code highlight theme and background
 */
const theme: Theme = {
  name: 'black-swan',
  mode: 'dark' as const,

  codeHighlightTheme: 'obsidian',

  colors: {
    background: '#0F1012',
    accent: '#F77669',
    // accent: '#ff837a',
    // text: '#90a2a4',
    text: '#E0F7FA',
    primary: '#E0F7FA',
    secondary: '#3E4147',
    onPrimary: '#0F1012',
    onSecondary: '#E0F7FA',
    shadow: '#0F1012',
    error: '#ff837a',
    onError: '#ffffff',
    codeHighlightBackground: '#282b2e',
  },

  fonts: {
    heading: {
      // family: 'OswaldVariable, sans-serif',
      family: '"Libre Baskerville", Baskerville, Garamond, serif',
      base: '0.95rem',
    },

    text: {
      // family: 'Ubuntu, sans-serif',
      family: '"Libre Franklin", sans-serif',
    },
  },
}

export default theme
