import type { FC } from 'react'
import Head from 'next/head'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Menu from './Menu'
import Footer from './Footer'
import ScrollToTop from './ScrollToTop'

const Grid = styled.div`
  --page-max-width: 65ch;

  :has(aside) {
    --page-max-width: calc(92ch + 2vw);
  }

  min-height: 100vh;
  min-height: 100dvb;
  display: grid;
  row-gap: 6vh;
  grid-template-areas: 'header' 'main' 'footer';
  grid-template-rows: 3rem auto max-content;
`

const Header = styled.header`
  --navbar-height: 3rem;

  grid-area: header;
  position: fixed;
  width: 100%;
  height: var(--navbar-height);
  line-height: var(--navbar-height);
  margin: 0;
  padding: 0;
  z-index: 1;
  isolation: isolate;
`

const Main = styled.main`
  --padding-inline: min(4vw, 2rem);

  grid-area: main;
  position: relative;
  inline-size: 100%;
  margin: 0 auto;
  contain: layout paint;
  container-type: inline-size;
  container-name: main;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem var(--padding-inline);

  & :is(h1, h2, h3, h4, h5, h6):has(svg) {
    position: relative;

    a {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      right: 0;
      bottom: 0;
      left: -1.15rem;
      opacity: 0;
      transition: opacity 120ms ease-in;
    }

    svg {
      inline-size: 1rem;
      fill: rgb(var(--color-text) / 70%);
    }

    &:hover a {
      transition-timing-function: ease-out;
      opacity: 1;
    }
  }
`

interface ContentProps {
  children: React.ReactNode
  className?: string
}

const Content: FC<ContentProps> = ({ children, className }) => {
  return (
    <div
      className={className}
      css={css`
        inline-size: 100%;
        order: 1;
        flex-shrink: 1;
        position: relative;
        margin: 0;
        padding: 0;

        &:has(aside) {
          display: flex;
          flex-flow: row wrap;
          flex-shrink: 0;
          gap: 2ch;
          justify-content: center;
          align-items: flex-start;

          article,
          aside {
            max-inline-size: 65ch;
          }
        }

        p {
          line-height: 1.7;
        }

        &:not(.fullscreen) {
          box-sizing: content-box;
          padding-inline: var(--padding-inline);
          inline-size: calc(100% - var(--padding-inline) * 2);
          max-inline-size: var(--page-max-width);
        }
      `}
    >
      {children}
    </div>
  )
}

const SideBar = styled.aside`
  order: 1;
  contain: layout paint;
  container-type: inline-size;
  container-name: sidebar;

  h2 {
    font-size: var(--font-heading-l);
    line-height: 1.3333;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
  }

  nav > ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 0.5rem;
    margin-block: 1rem;
  }

  inline-size: 100%;

  @container main (min-width: 92ch) {
    border-radius: 0.2rem;
    order: 0;
    padding: 1rem;
    inline-size: 25ch;
    position: sticky;
    top: 4.2rem;

    nav > ul {
      flex-direction: column;
    }

    // box-shadow: 1px 1px 5px hsl(0deg 0% 0% / 40%);
    // background-color: rgb(var(--color-background-200));
    // block-size: max-content;
  }
`

interface Props {
  children: React.ReactNode
}

const Layout: FC<Props> = ({ children }) => {
  return (
    <Grid>
      <Head>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Header>
        <Menu />
      </Header>

      <Main>{children}</Main>

      <ScrollToTop />

      <Footer />
    </Grid>
  )
}

export { Layout as default, Content, SideBar }
