import type { Theme } from '@emotion/react'

const defaultTheme: Partial<Theme> = {
  spacing: {
    ratio: 'minorThird',
  },
  fonts: {
    heading: {
      family: 'serif',
      base: '1rem',
    },
    text: {
      family: 'sans-serif',
      base: '1rem',
    },
  },
}

export const createThemesSync = (rawThemes: Theme[]) => {
  return rawThemes.reduce<Record<string, Required<Theme>>>((themes, theme) => {
    themes[theme.name] = createTheme(theme)

    return themes
  }, {})
}

export const createTheme = (rawTheme: Theme) => {
  const theme = { ...defaultTheme, ...rawTheme }

  if (!theme.codeHighlightTheme) {
    theme.codeHighlightTheme =
      theme.mode === 'light' ? 'base16/tomorrow' : 'night-owl'
  }

  theme.hljsTheme = theme.codeHighlightTheme

  if (!theme.codeHighlightTheme.endsWith('.css')) {
    theme.hljsTheme = `https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.6.0/styles/${theme.codeHighlightTheme}.min.css`
  }

  if (!theme.displayName) {
    theme.displayName = theme.name.replace('-', ' ')
  }

  if (!theme.icon) {
    theme.icon = theme.mode === 'light' ? 'sun' : 'moon'
  }

  return theme as Required<Theme>
}
