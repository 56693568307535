import { forwardRef, ReactNode } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const desktopStyles = `
  position: revert;
  padding: 0;
  gap: clamp(0.2rem, 0.5cqi + 0.2em, 1.5rem);
  flex-direction: row;
  opacity: 1;
  min-height: revert;
  font-size: var(--font-text-s);
  line-height: var(--navbar-height);
  transform: translate3d(0, 0, 0) scale(1, 1);
  transition: revert;
  background-color: revert;
  backdrop-filter: revert;
`

const StyledList = styled.ul<{ isOpen?: boolean }>`
  position: absolute;
  top: var(--navbar-height);
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: var(--font-heading);
  list-style-type: none;
  text-indent: 0;
  margin: 0;
  padding: 0 1.5rem 0.75rem;
  opacity: 0;
  z-index: 100;
  isolation: isolate;
  background-color: rgb(var(--color-secondary-50));
  transition: opacity 250ms ease-in, transform 0ms ease-in 251ms;
  transform: translate3d(80%, -15%, 0) scale(0.2, 0.9);
  transform-origin: right;
  min-block-size: calc(100vh - var(--navbar-height));
  min-block-size: calc(100dvb - var(--navbar-height));
  font-size: clamp(var(--font-heading-xl), 5vmax, var(--font-heading-3xl));
  line-height: clamp(2em, 15vmax, 2.5em);
  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1, 1);
      transition-timing-function: ease-out;
      transition-property: opacity, transform;
      transition-duration: 250ms;

      @media (prefers-reduced-motion: reduce) {
        transition-property: opacity;
        transition-timing-function: linear;
      }
    `};

  li {
    flex: 1 1 100%;
    inline-size: 100%;
    display: flex;
    align-items: center;
  }
`

interface Props extends React.HTMLAttributes<HTMLElement> {
  children: ReactNode
  isOpen: boolean
}

const List = forwardRef<HTMLUListElement, Props>(
  ({ children, isOpen, ...otherProps }, ref) => {
    return (
      <StyledList ref={ref} isOpen={isOpen} tabIndex={-1} {...otherProps}>
        {children}
      </StyledList>
    )
  }
)

List.displayName = 'List'

export default List
