import { forwardRef, type ReactNode } from 'react'
import { css } from '@emotion/react'
import { cx } from '@emotion/css'

const contentStyles = css`
  --border-radius: 0.2em;
  --bg-opacity: 100%;
  --bg-color: rgb(var(--color-primary) / var(--bg-opacity));
  --text-color: rgb(var(--color-background));
  --triangle-height: 10px;
  --triangle-base: 7px;

  inline-size: max-content;
  border-radius: var(--border-radius);
  box-shadow: 3px 3px 2px rgb(var(--color-shadow) / 30%);
  background-color: var(--bg-color);
  backdrop-filter: blur(2px);
  color: var(--text-color);
  padding: 0.5em 0.7em;

  *:is(a, button):is(*, :hover, :focus) {
    color: var(--text-color);
  }

  &::before {
    display: block;
    position: absolute;
    content: '';
    border-style: solid;
    border-width: 0 var(--triangle-base) var(--triangle-height)
      var(--triangle-base);
    border-color: transparent transparent var(--bg-color) transparent;
    top: calc(-1 * var(--triangle-height));
    left: 50%;
    margin-left: calc(-1 * var(--triangle-base));
  }

  line-height: 1.5;

  @media (min-width: 700px) {
    --bg-opacity: 80%;
  }
`

interface ContentProps {
  children: ReactNode
  className?: string
  isMounted: boolean
}

export const PopoverContent = forwardRef<HTMLDivElement, ContentProps>(
  ({ children, className, isMounted, ...otherProps }, ref) => {
    if (!isMounted) return null

    return (
      <div
        aria-label="Theme Switcher"
        ref={ref}
        css={contentStyles}
        className={cx(className)}
        {...otherProps}
      >
        {children}
      </div>
    )
  }
)

PopoverContent.displayName = 'PopoverContent'

export { usePopover } from './usePopover'
export { PopoverTrigger } from './Trigger'
