
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import type { AppProps } from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import { ApolloProvider } from '@apollo/client';
import { Analytics } from '@vercel/analytics/react';
import 'nprogress/nprogress.css';
import '../styles/globals.css';
import Layout from '@components/Layout';
import GlobalStyles from '@components/GlobalStyles';
import { ThemeProvider } from '../theme/Context';
import { getClient } from '@graphql/withApollo';
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());
const App = ({ Component, pageProps, err }: AppProps & {
    err: Error;
}) => {
    return (<>
      <ThemeProvider>
        <GlobalStyles />
        <Layout>
          <ApolloProvider client={getClient()}>
            <Component {...pageProps} err={err}/>
          </ApolloProvider>
        </Layout>
      </ThemeProvider>
      <Analytics />
    </>);
};
const __Next_Translate__Page__1898874f592__ = App;
export { reportWebVitals } from 'next-axiom';

    export default __appWithI18n(__Next_Translate__Page__1898874f592__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  