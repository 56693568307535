import type { Theme } from '@emotion/react'
import '@fontsource-variable/raleway'
import '@fontsource/lato/latin.css'

/**
 * TODO Set code highlight theme and background
 */
const theme: Theme = {
  name: 'lucky-luke',
  mode: 'dark' as const,

  codeHighlightTheme: 'pojoaque',

  colors: {
    // background: '#ECEFF1',
    background: '#152127',
    accent: 'crimson',
    text: '#ECEFF1',
    primary: 'floralwhite',
    secondary: 'gold',
    // secondary: '#f6d065',
    shadow: 'black',
    onPrimary: '#ffffff',
    onSecondary: '#3d1010',
    // onSecondary: '#ECEFF1',
    error: 'crimson',
    onError: '#ffffff',
    codeHighlightBackground: '#161712',
  },

  fonts: {
    heading: {
      family: 'RalewayVariable, sans-serif',
    },

    text: {
      family: 'Lato, sans-serif',
    },
  },
}

export default theme
