import { memo, type FC } from 'react'
import { Global } from '@emotion/react'

import '../icons-library'
import { themes, themeToCustomProps } from '../theme'

const GlobalStyles: FC = () => {
  const themeClasses = Object.values(themes).map((theme) => {
    return `
      .${theme.name} {
        ${themeToCustomProps(theme)}
      }
    `
  })

  return <Global styles={themeClasses} />
}

export default memo(GlobalStyles)
