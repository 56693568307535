/* eslint-disable @typescript-eslint/no-unused-vars */
import { print } from 'graphql'
import {
  wrapSchema,
  introspectSchema,
  schemaFromExecutor,
  FilterRootFields,
} from '@graphql-tools/wrap'
import type { AsyncExecutor } from '@graphql-tools/utils'
import { fetch } from 'cross-undici-fetch'

const executor: AsyncExecutor = async ({ document, variables }) => {
  const query = print(document)
  const fetchResult = await fetch(process.env.GRAPHCMS_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.GRAPHCMS_TOKEN}`,
    },
    body: JSON.stringify({ query, variables }),
  })
  return fetchResult.json()
}

const loadLocalSchema = async () => {
  const { loadSchema } = await import('@graphql-tools/load')
  const { GraphQLFileLoader } = await import(
    '@graphql-tools/graphql-file-loader'
  )

  return loadSchema('./src/graphql/generated/graphcms.schema.graphql', {
    loaders: [new GraphQLFileLoader()],
  })
}

export const createGraphcmsSubSchema = async () => {
  const whitelistedOperations = ['createPostComment']

  const isProd = process.env.NODE_ENV === 'production'

  return wrapSchema({
    executor,
    schema: await (isProd ? schemaFromExecutor(executor) : loadLocalSchema()),
    transforms: [
      new FilterRootFields((operation, rootField) => {
        // Remove all mutation operations unless whitelisted
        return (
          operation !== 'Mutation' || whitelistedOperations.includes(rootField)
        )
      }),
    ],
  })
}
