import { Icon } from '@components/Icon'
import { css } from '@emotion/react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'

const styles = css`
  font-size: var(--font-text-xs);
  font-weight: 300;
  inline-size: 100%;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 0.45em;

  &,
  a {
    color: rgb(var(--color-on-secondary) / var(--opacity, 100%));
    transition: color 80ms ease-in-out;
  }

  a,
  span {
    min-inline-size: max-content;
  }

  a {
    display: inline-flex;
    column-gap: 0.25em;
    align-items: center;
    text-decoration-skip-ink: auto;
    text-decoration: dotted underline;
    font-weight: 400;

    &:is(:hover, :focus-visible) {
      outline: none;
      color: rgb(var(--color-accent-500));
      text-decoration-style: solid;
    }
  }
`

const Copyright = () => {
  const { t } = useTranslation('common')
  const year = new Date().getFullYear()

  return (
    <div css={styles}>
      <span>
        {t('copyright', { year })} <Link href="/">Ousmane Ndiaye</Link> |{' '}
      </span>
      <a href="/sitemap.xml">
        <Icon icon="sitemap" />
        {t('sitemap')}
      </a>
    </div>
  )
}

export default Copyright
