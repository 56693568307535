import React, {
  Fragment,
  FC,
  MutableRefObject,
  useState,
  useRef,
  useEffect,
  forwardRef,
  useCallback,
} from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { fadeInUp, fadeOutDown } from '../utils/animation'
import { Icon } from './Icon'

const Anchor = styled.button<{ isShowing: boolean }>`
  --size: clamp(2rem, 3vmax, 3.5rem);

  position: fixed;
  right: 2vmax;
  bottom: 2vmax;
  display: ${({ isShowing }) => (isShowing ? 'grid' : 'none')};
  transition: background-color 100ms linear;
  border-radius: 25%;
  inline-size: var(--size);
  block-size: var(--size);
  place-items: center;
  box-shadow: 2px 2px 6px 1px rgb(var(--color-shadow) / 50%);
  background-color: rgb(var(--color-primary) / 50%);
  z-index: 10;
  backdrop-filter: blur(1px);

  svg {
    color: rgb(var(--color-background-500));
    inline-size: 50%;
    block-size: 50%;
  }

  &:hover {
    background-color: rgb(var(--color-primary) / 100%);
  }
`

const ScrollToTop: FC = () => {
  const { t } = useTranslation('common')
  const [isShowing, setIsShowing] = useState(false)
  const timing: KeyframeAnimationOptions = {
    easing: 'ease-out',
    duration: 150,
    fill: 'both',
  }

  const ref: MutableRefObject<HTMLButtonElement | null> = useRef(null)
  const triggerRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const observer: MutableRefObject<IntersectionObserver | null> = useRef(null)

  if (typeof IntersectionObserver !== 'undefined') {
    observer.current = new IntersectionObserver(
      ([entry]) => {
        if (!isShowing && entry.isIntersecting) {
          setIsShowing(true)
          ref?.current?.animate(fadeInUp, timing)
        }

        if (!entry.isIntersecting) {
          const animation = ref?.current?.animate(fadeOutDown, {
            ...timing,
            easing: 'ease-in',
            duration: 200,
          })

          if (animation) {
            animation.onfinish = () => {
              setIsShowing(false)
            }
          }
        }
      },
      { threshold: 0.1 }
    )
  }

  useEffect(() => {
    // Stored for clean up
    const currentRef = triggerRef.current
    if (triggerRef.current) {
      observer?.current?.observe(triggerRef.current)
    }

    return () => {
      if (currentRef) {
        observer?.current?.unobserve(currentRef)
      }
    }
  }, [])

  return (
    <Fragment>
      <Trigger ref={triggerRef} />
      <Anchor
        ref={ref}
        title={t('back_to_top')}
        isShowing={isShowing}
        onClick={() => {
          document.documentElement.scrollIntoView(true)
        }}
      >
        <Icon icon="chevron-up" />
      </Anchor>
    </Fragment>
  )
}

interface TriggerProps {
  offset?: number
}

const Trigger = forwardRef<HTMLDivElement, TriggerProps>(function InnerTrigger(
  { offset = 150 },
  ref
) {
  const router = useRouter()
  const setTriggerHeight = useCallback(() => {
    const mutableRef = ref as MutableRefObject<HTMLDivElement | null>
    if (mutableRef.current) {
      const [main] = document.getElementsByTagName('main')
      const vh = window.visualViewport
        ? window.visualViewport.height
        : window.innerHeight
      const height = main.getBoundingClientRect().height - (offset / 100) * vh
      mutableRef.current.style.height = height < 0 ? '0px' : `${height}px`
    }
  }, [offset, ref])

  useEffect(() => {
    setTriggerHeight()
    window.addEventListener('resize', setTriggerHeight)
    router.events.on('routeChangeComplete', setTriggerHeight)

    return () => {
      window.removeEventListener('resize', setTriggerHeight)

      router.events.off('routeChangeComplete', setTriggerHeight)
    }
  }, [])

  return (
    <div
      ref={ref}
      css={css({
        position: 'absolute',
        top: offset + 'vh',
        height: `calc(100% - ${offset}vh - 3rem)`,
        visibility: 'hidden',
      })}
    />
  )
})

export default ScrollToTop
