export const fadeInUp = [
  { opacity: 0, transform: 'translate3d(0, 2vmax, 0) scale(0, 0)' },
  { opacity: 1, transform: 'translate3d(0, 0, 0) scale(1, 1)' },
]

export const fadeOutDown = Array.from(fadeInUp).reverse()

export const fadeInDown = [
  { opacity: 0, transform: 'translate3d(50%, -10%, 0) scale(0, 0)' },
  { opacity: 1, transform: 'translate3d(0, 0, 0) scale(1, 1)' },
]

export const fadeOutUp = Array.from(fadeInDown).reverse()
