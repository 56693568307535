import type { FC } from 'react'

interface Props {
  children: React.ReactNode
  href: string
  title?: string
  className?: string
}

const OutboundLink: FC<Props> = ({ children, href, title, className }) => (
  <a
    target="_blank"
    className={className}
    rel="noopener noreferrer"
    href={href}
    title={title}
  >
    {children}
  </a>
)

export default OutboundLink
