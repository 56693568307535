import { log } from 'next-axiom'
import type { PluginDefinition } from 'apollo-server-core'

const ApolloRequestLogger: PluginDefinition = {
  /* eslint-disable @typescript-eslint/require-await */
  async requestDidStart(context) {
    // if (process.env.NODE_ENV !== 'production') return

    const query = context.request.query?.replace(/\n/g, '')
    const variables = Object.keys(context.request.variables || {})
    log.debug('received request', { query, variables })

    return {
      async parsingDidStart() {
        log.debug('Parsing Started')
      },

      async validationDidStart() {
        log.debug('Parsing Started')
      },

      async didEncounterErrors({ errors }) {
        log.info('got errors!', { errors })
      },

      async willSendResponse({ metrics }) {
        log.info('Response', { metrics })
      },
    }
  },
  /* eslint-enable @typescript-eslint/require-await */
}

export { log, ApolloRequestLogger }
