import type { FC } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

interface HeadingProps {
  children: React.ReactNode
}

const Heading: FC<HeadingProps> = ({ children }) => (
  <h2
    className="my-2 font-bold capitalize"
    css={css`
      font-size: var(--font-heading-m);
    `}
  >
    {children}
  </h2>
)

const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  text-indent: 0;
  font-size: var(--font-text-s);

  svg,
  div {
    inline-size: 1.3em;
    max-block-size: 1.3em;
    block-size: 1.3em;
  }

  a,
  button {
    gap: 0.5rem;
    display: inline-flex;
    align-items: center;
    text-transform: capitalize;
    transition: transform ease 200ms;
    transform-origin: left;
    flex-flow: row;

    &:visited {
      // color: rgb(var(--color-primary-400));
    }

    &:active {
      color: rgb(var(--color-primary-300));
    }

    &:hover,
    &:focus-visible {
      outline: none;
      color: rgb(var(--color-accent-500));
      filter: drop-shadow(0 0 2px hsl(0deg 0% 100% / 100%));
      transform: scale3d(1.02, 1.04, 1) translate3d(0, -1px, 0);
    }
  }
`

const Nav = styled.nav`
  flex: 1 0;
  min-inline-size: max-content;
`

export { Nav as default, Heading, List }
