import type { ThemeMode } from '@emotion/react'

import { createThemesSync } from './utils'
import karmicKoala from './themes/karmic-koala'
import blackSwan from './themes/black-swan'
import warmAntique from './themes/warm-antique'
import daringDarling from './themes/daring-darling'
// import coolBreeze from './themes/cool-breeze'
import luckyLuke from './themes/lucky-luke'

const themes = createThemesSync([
  daringDarling,
  karmicKoala,
  luckyLuke,
  warmAntique,
  blackSwan,
  // coolBreeze,
])

const defaultThemes: Record<ThemeMode, string> = {
  dark: 'daring-darling',
  light: 'karmic-koala',
}

export { defaultThemes, themes }
export { themeToCustomProps } from '@utils/theme'
