import type { Theme, ThemeMode } from '@emotion/react'
import { darken, desaturate, modularScale, parseToRgb } from 'polished'
import { log } from '../utils/logger'

const shadesNames: Array<string | number> = [
  'default',
  50,
  100,
  200,
  300,
  400,
  500,
  600,
  700,
  800,
  900,
]

const toRgb = (color: string) => {
  return Object.values(parseToRgb(color)).join(' ')
}

const camelCaseToSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, (char) => `-${char.toLowerCase()}`)
}

const generateColorShades =
  (mode: ThemeMode) =>
  ([name, baseColor]: [string, string | object]) => {
    const dir = mode === 'light' ? 1 : -1
    const coeffLuminosity = 0.025 * dir
    const coeffSaturation = 0.015 * dir
    const shades = shadesNames.map((shadeName) => {
      const shadeKey = shadeName === 'default' ? 0 : 500 - (shadeName as number)

      const lumValue = coeffLuminosity * (shadeKey / 100)
      const satValue = coeffSaturation * (shadeKey / 100)
      const shade = toRgb(
        darken(lumValue, desaturate(satValue, baseColor as string))
      )

      return [shadeName, shade]
    })

    return [
      name,
      { default: toRgb(baseColor as string), ...Object.fromEntries(shades) },
    ]
  }

export const addShadesToTheme = ({ colors, ...theme }: Theme) => {
  const colorsToShade = ['primary', 'secondary', 'background', 'accent', 'text']
  // Means we lighten in light mode and darken in dark mode
  // TODO move away from hackiness
  const shadedColors = Object.fromEntries(
    Object.entries(colors)
      .filter(([name]) => colorsToShade.includes(name))
      .map(generateColorShades(theme.mode))
  )

  return {
    ...theme,
    colors: {
      ...colors,
      ...shadedColors,
    },
  }
}

export const themeToCustomProps = (rawTheme: Theme) => {
  const theme = addShadesToTheme(rawTheme)

  const colorProps = Object.entries(theme.colors).flatMap(([key, color]) => {
    const colorName = camelCaseToSnakeCase(key)
    if (typeof color === 'string') {
      return `--color-${colorName}: ${toRgb(color)};`
    }

    const shades = Object.entries(color).map(([name, shade]) => {
      const shadeName = name === 'default' ? colorName : `${colorName}-${name}`
      return `--color-${shadeName}: ${shade};`
    })

    return shades
  })

  const ratio = theme?.spacing?.ratio
  const { base: textBase } = theme.fonts.text
  const { base: headingBase } = theme.fonts.heading

  // TODO clean up temporary work
  let modeOverrides
  if (theme.mode === 'dark') {
    modeOverrides = `
      img {
        filter: brightness(0.8) contrast(1.2);
        transition: filter 300ms ease-in-out;

        &:hover {
          filter: brightness(1) contrast(1);
        }
      }
    `
  }
  const props = `
    ${colorProps.join('\n')}

    --font-heading: ${theme.fonts.heading.family};
    --font-text: ${theme.fonts.text.family};

    --font-text-xs: ${modularScale(-2, textBase, ratio)};
    --font-text-s: ${modularScale(-1, textBase, ratio)};
    --font-text-m: ${modularScale(0, textBase, ratio)};
    --font-text-l: ${modularScale(1, textBase, ratio)};

    --font-heading-xs: ${modularScale(-2, headingBase, ratio)};
    --font-heading-s: ${modularScale(-1, headingBase, ratio)};
    --font-heading-m: ${modularScale(0, headingBase, ratio)};
    --font-heading-l: ${modularScale(1, headingBase, ratio)};
    --font-heading-xl: ${modularScale(2, headingBase, ratio)};
    --font-heading-2xl: ${modularScale(3, headingBase, ratio)};
    --font-heading-3xl: ${modularScale(4, headingBase, ratio)};

    ${modeOverrides}
  `

  return props
}
