import { forwardRef, type ReactNode } from 'react'
import { css } from '@emotion/react'
import { cx } from '@emotion/css'

import { Icon } from '@components/Icon'

const triggerStyles = css`
  height: inherit;
  display: flex;
  gap: 0.25em;
  align-items: center;

  // *:where(svg:not(.indicator)) {
  //   block-size: 1.1em;
  // }

  svg.indicator {
    inline-size: 0.6em;
    color: rgb(var(--color-on-secondary) / 70%);
    transform: rotate(0deg);
    transition: transform 120ms ease-out;
  }

  &:is(:hover, :focus) {
    outline: none;
    color: rgb(var(--color-accent-500));
  }

  &.open svg.indicator {
    transform: rotate(-180deg);
  }
`

interface TriggerProps {
  children: ReactNode
  className?: string
  isOpen: boolean
}

export const PopoverTrigger = forwardRef<HTMLButtonElement, TriggerProps>(
  ({ children, className, isOpen, ...otherProps }, ref) => {
    return (
      <button
        type="button"
        ref={ref}
        css={triggerStyles}
        className={cx(className, { ['open']: isOpen })}
        {...otherProps}
      >
        {children} <Icon icon="chevron-down" className="indicator" />
      </button>
    )
  }
)

PopoverTrigger.displayName = 'PopoverTrigger'
