import {
  useState,
  MouseEventHandler,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react'

import useHeaderMenuItems, { MenuItem } from './useHeaderMenuItems'

interface UseMenuResult {
  menuItems: MenuItem[]
  isOpen: boolean
  openMenu: MouseEventHandler
  closeMenu: MouseEventHandler
  toggle: MouseEventHandler
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const useMenu = (): UseMenuResult => {
  const menuItems: MenuItem[] = useHeaderMenuItems()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto'
  }, [isOpen])

  const openMenu: MouseEventHandler = (event) => {
    event.preventDefault()

    setIsOpen(true)
  }

  const closeMenu: MouseEventHandler = (event) => {
    event.preventDefault()

    setIsOpen(false)
  }

  const toggle: MouseEventHandler = (event) => {
    event.preventDefault()

    setIsOpen(!isOpen)
  }

  return {
    menuItems,
    isOpen,
    openMenu,
    closeMenu,
    toggle,
    setIsOpen,
  }
}

export default useMenu
