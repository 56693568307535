// @ts-nocheck
// This file is auto generated. Do not modify
import { z } from 'zod'
import { ContactInput } from './'

type Properties<T> = Required<{
  [K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny => v !== undefined && v !== null;

export const definedNonNullAnySchema = z.any().refine((v) => isDefinedNonNullAny(v));

export function ContactInputSchema(): z.ZodObject<Properties<ContactInput>> {
  return z.object<Properties<ContactInput>>({
    email: z.string().email("invalid_email"),
    message: z.string().min(1, "required"),
    name: z.string().min(1, "required")
  })
}
