import { useMemo, useState } from 'react'

import {
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react'

import type { Middleware } from '@floating-ui/core'

interface PopoverProps {
  isOpen?: boolean
  onOpenChange?: (open: boolean) => void
}

export const usePopover = ({
  isOpen,
  onOpenChange: setIsOpen,
}: PopoverProps) => {
  const [labelId, setLabelId] = useState<string | undefined>()
  const [descriptionId, setDescriptionId] = useState<string | undefined>()

  const middleware: Middleware[] = []

  const data = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware,
  })

  const context = data.context

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  // const listNav = useListNavigation(context, )

  const interactions = useInteractions([click, dismiss, role])

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: 'scaleY(0.6) translateY(-20px)',
    },
    common: {
      transformOrigin: 'top',
    },
  })

  return useMemo(
    () => ({
      isOpen,
      setIsOpen,
      isMounted,
      transitionStyles,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [
      isOpen,
      setIsOpen,
      isMounted,
      transitionStyles,
      interactions,
      data,
      labelId,
      descriptionId,
    ]
  )
}
