// eslint-disable-next-line @typescript-eslint/no-var-requires
const { library } = require('@fortawesome/fontawesome-svg-core')
import {
  faGithub,
  faGitlab,
  faGoodreads,
  faLinkedin,
  faTwitter,
  faMastodon,
} from '@fortawesome/free-brands-svg-icons'
import {
  faAddressCard,
  faArrowLeft,
  faBars,
  faBlog,
  faCaretUp,
  faCaretDown,
  faChevronUp,
  faChevronDown,
  faCode,
  faCodeBranch,
  faFile,
  faGraduationCap,
  faHome,
  faLanguage,
  faLink,
  faMoon,
  faSun,
  faTimes,
  faAnglesRight,
  faStopwatch,
  faSquare,
  faGear,
  faRss,
  faRssSquare,
  faSitemap,
} from '@fortawesome/free-solid-svg-icons'

import {
  faCalendar,
  faHourglass,
  faClock,
  faPaperPlane,
} from '@fortawesome/free-regular-svg-icons'

library.add(
  faAddressCard,
  faArrowLeft,
  faBars,
  faBlog,
  faCaretUp,
  faCaretDown,
  faChevronUp,
  faChevronDown,
  faCode,
  faCodeBranch,
  faFile,
  faGear,
  faGithub,
  faGitlab,
  faGoodreads,
  faGraduationCap,
  faHome,
  faLanguage,
  faLink,
  faLinkedin,
  faMoon,
  faSun,
  faTimes,
  faTwitter,
  faAnglesRight,
  faClock,
  faHourglass,
  faCalendar,
  faStopwatch,
  faMastodon,
  faPaperPlane,
  faRss,
  faRssSquare,
  faSitemap,
  faSquare
)
