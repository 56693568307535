import { useRouter } from 'next/router'
import type { Translate } from 'next-translate'
import useTranslation from 'next-translate/useTranslation'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface MenuItem {
  name: string
  path: string
  icon: IconProp
  active: boolean
}

interface InputMenuItem extends Omit<MenuItem, 'active'> {
  strict?: boolean
}

const spaceToNbsp = (item: MenuItem): MenuItem => {
  return { ...item, name: item.name.replaceAll(' ', '\u00A0') }
}

const translateName =
  (t: Translate) =>
  (item: MenuItem): MenuItem => {
    return { ...item, name: t(`menu_${item.name}`) }
  }

const isPathActive = (
  { strict, ...item }: InputMenuItem,
  path: string
): MenuItem => {
  const active = path === item.path || (!strict && path.startsWith(item.path))

  return { ...item, active }
}

const items: InputMenuItem[] = [
  { name: 'home', path: '/', icon: 'home', strict: true },
  { name: 'blog', path: '/blog', icon: 'blog' },
  // { name: 'reading', path: '/reading', icon: 'fa-book-open-reader' },
  { name: 'resume', path: '/resume', icon: 'graduation-cap' },
  { name: 'about', path: '/about-me', icon: 'address-card' },
  { name: 'contact', path: '/contact', icon: ['far', 'paper-plane'] },
]

const useHeaderMenuItems = () => {
  const { asPath } = useRouter()
  const { t } = useTranslation('common')
  const translate = translateName(t)

  return items.map((item) => {
    return spaceToNbsp(translate(isPathActive(item, asPath)))
  })
}

export default useHeaderMenuItems
