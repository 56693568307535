interface ConfigProps {
  siteUrl: string
  siteRepo: {
    url: string
    platform: string
  }
  graphcmsCdnUrl: string
  seo: {
    titleTemplate: string
    defaultTitle: string
    defaultDescription: string
    facebookAppId: string
  }
  socials: {
    twitter: string
    linkedin: string
    mastodon: string
    gitlab: string
    github: string
    goodreads: string
  }
}

// TODO Set up actual copy
const Config: ConfigProps = {
  siteUrl: 'https://ornous.vercel.app',
  siteRepo: {
    url: 'https://gitlab.com/ornous-labs/ornous-next',
    platform: 'gitlab',
  },
  graphcmsCdnUrl: 'https://media.graphassets.com',
  seo: {
    titleTemplate: '%s | Ornous',
    defaultTitle: 'Software Engineering | Ornous',
    defaultDescription:
      'Join Ousmane Ndiaye on his journey as a full stack developer and platform engineer. View his resume and blog to see his skills and experiences in action.',
    facebookAppId: '464901247584397',
  },
  socials: {
    twitter: 'ornous',
    linkedin: 'ornous',
    mastodon: 'ornous',
    gitlab: 'ornous',
    github: 'ornous',
    goodreads: 'ornous',
  },
}

export default Object.freeze(Config)
