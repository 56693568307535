import type { FC } from 'react'
import styled from '@emotion/styled'
import NextLink, { type LinkProps } from 'next/link'

const Link = styled(NextLink)`
  display: inline-block;
  position: relative;
  padding: 0 0.1em;
  opacity: 1;
  text-decoration: none;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  font-weight: 500;
  transition: opacity 100ms ease-in;
  inline-size: inherit;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: currentcolor;
    height: 4px;
    width: 100%;
    transform: scale3d(0, 0, 0);
    transition: transform ease-in 150ms;
  }

  &:hover,
  &:focus-visible {
    color: rgb(var(--color-on-secondary) / 80%);
    outline: 0;

    &::after {
      transition: transform ease-out 180ms;
      transform: scale3d(1, 1, 1);
    }
  }

  &.active {
    box-shadow: inset 0 -4px 0 rgb(var(--color-on-secondary) / 80%);
    background-color: rgb(var(--color-on-secondary-400) / 10%);
  }

  &:visited {
    color: rgb(var(--color-on-secondary));
  }
`
interface Props
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps>,
    LinkProps {
  children: React.ReactNode
  active?: boolean
}

const NavLink: FC<Props> = ({ children, href, active, ...rest }) => {
  return (
    <li>
      <Link
        aria-current={active ? 'page' : false}
        className={active ? 'active' : ''}
        href={href}
        {...rest}
      >
        {children}
      </Link>
    </li>
  )
}

export default NavLink
