import { ApolloClient, InMemoryCache } from '@apollo/client'
import { type ASTNode, graphql, print } from 'graphql'
import type { Maybe } from '@graphql-tools/utils'

import { createExecutableSchema } from '@graphql/schema'
import { log } from '@utils/logger'

const query = async (
  doc: ASTNode,
  variables?: Maybe<{ [key: string]: unknown }>
) => {
  return graphql(
    await createExecutableSchema(),
    print(doc),
    null,
    { logger: log },
    variables
  )
}

const getClient = () => {
  return new ApolloClient({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    ssrMode: typeof window === 'undefined',
    cache: new InMemoryCache(),
  })
}

export { getClient, query }
